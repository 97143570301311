@import url('https://fonts.googleapis.com/css?family=Roboto');

/* Global Styles */
:root {
  --primary-color: #dc3545;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --test: linear-gradient(40deg, blue, pink);
}

.square {
  text-align: center;
  /* background-color: rgba(3, 78, 136, 0.7); */
  width: 150px;
  height: 150px;
  line-height: 60px;
  color: white;
  /* margin: 20px auto; */
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
}

.short-vertical-line {
  /* border-left: 6px solid green; */
  border-left: 3px solid;
  height: 75px;
}
.title-width {
  /* border-left: 6px solid green; */
  width: 60px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.lineup-image {
  height: 75px;
  width: 75px;
  max-width: none;
  padding: 0%;
}
.vertical-center {
  /* margin: 0; */
  /* position: absolute; */
  /* top: 50%; */
  /* -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  /* color: var(--primary-color); */
  /* color: rgb(0, 0, 0); */
  color: white;
  text-decoration: none;
}
a:hover {
  color: #666;
  text-decoration: underline;
}

ul {
  list-style: none;
}

/* img {
  width: 100%;
} */

.vertical-artist-container {
  display: flex;
  flex-direction: col;
  /* height: 40vh; */
  max-height: 40vh;

  overflow-y: scroll;
}
.vertical-attendee-container {
  display: flex;
  flex-direction: col;
  /* height: 40vh; */
  /* width:  */
  max-height: 40vh;
  overflow-y: scroll;
}
.grid-container-big-screen-main-timeline {
  display: inline-grid;
  grid-template-columns: auto auto auto auto;
  /* background-color: #2196f3;
  padding: 10px; */
  column-gap: 8px;
}
.grid-container-big-screen {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  /* background-color: #2196f3;
  padding: 10px; */
  column-gap: 8px;
}

.rankingImageText {
  max-inline-size: 16ch;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}
.ellipsisText {
  max-inline-size: 208ch;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  max-width: 180px;
}

.center-col {
  flex: 1;
  background: #aaa;
  overflow-y: scroll;
}

.bigScreen img {
  width: 400px;
}
.bigScreenAttendee img {
  width: 100px;
  height: 100px;
}
.bigScreenRoommateRequest img {
  width: 100px;
  height: 100px;
}
.bigScreenHost img {
  width: 50px;
  height: 50px;
}
.bigScreenEvents img {
  width: 250px;
  height: 250px;
}

.rating {
  width: 180px;
}

.rating__star {
  cursor: pointer;
  color: #dabd18b2;
}

/* Utilities */

.auto-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.autoContainer {
  position: absolute;
  border-width: 0px 1px 1px 1px;
  /* border-style: solid; */
  /* border-color: black; */
  background-color: white;
  right: 10px;
  /* top: 10px; */
  left: 85px;
  width: 320px;
  opacity: 1;
  z-index: 1;
  border-radius: 0.2rem;
  border: 0.5px solid;
  padding: 1px;
  box-shadow: 1px 1px #888888;
}
.autoContainer2 {
  /* position: absolute; */
  border-width: 0px 1px 1px 1px;
  /* border-style: solid; */
  /* border-color: black; */
  background-color: white;
  right: 10px;
  /* top: 10px; */
  left: 85px;
  width: 320px;
  opacity: 1;
  z-index: 1;
  border-radius: 0.2rem;
  border: 0.5px solid;
  padding: 1px;
  box-shadow: 1px 1px #888888;
}
.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  padding: 5px;
}

.option,
span {
  font-size: 28px;
  text-transform: capitalize;
}

.flex-container {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.pos-rel {
  position: relative;
}

.my-modal {
  width: '100px'; /* Occupy the 90% of the screen width */
  /* max-width: 90vw; */
}
.modal-90w {
  max-width: 60% !important;
}

.container {
  max-width: 700px;
  margin: auto;
  /* overflow: hidden; */
  padding: 0 0.5rem;
  /* padding: 0 2rem; */
}
.container2 {
  /* max-width: 700px; */
  /* max-width: min-content; */
  /* margin: auto; */
  /* overflow: hidden; */
  padding: 0 1rem;
}
.approval-container {
  /* max-width: 700px; */
  min-width: 1000px;
  margin: auto;
  /* overflow: hidden; */
  padding: 0 2rem;
}
.containerDropDownNotHidden {
  max-width: 700px;
  margin: auto;
  /* overflow: hidden; */
  padding: 0 2rem;
}
.attendeeContainer {
  max-width: 700px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  background-color: green;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-success {
  color: var(--success-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.adjust-line-height {
  line-height: 1.2em;
}

.artistStyle {
  border-radius: 1rem;
}

/* Center All */
.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.all-center-img {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.all-center-2-for-text {
  /* display: flex;
  flex-direction: column;
  width: 100%; */
  /* margin: auto; */
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Cards */
.card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}
.card-colored-border {
  padding: 1rem;
  border: #ccc 2px solid;
  margin: 0.7rem 0;
  border-color: #66b2ff;
  border-radius: 10px;
  background-color: white;
}
.card-horizontal-menu {
  /* padding: 1rem; */
  /* border: #ccc 1px dotted; */
  margin: 0.7rem 0;
}
.card-horizontal-menu2 {
  /* padding: 1rem; */
  /* border: #ccc 1px dotted; */
  margin: 0.7rem 0;
}
.card2 {
  padding: 0.3rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}
.search-card {
  /* padding: 1rem; */
  /* border: #ccc 1px solid; */
  /* margin: 0.7rem 0; */
  width: 300px;
}
.timelineCard {
  /* padding: 1rem; */
  padding-top: 0rem;
  /* border: #ccc 1px dotted; */
  margin: 0.7rem 0;
}
.timelineCardTopDancers {
  padding: 1rem;
  padding-top: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
}

.attendee-card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.2rem 0;
}
.curriculum-card {
  padding: 1rem;
  border: #ccc 1px solid;
  margin: 0.2rem 2;
}
.curriculum-week-card {
  padding: 0.1rem;
  padding-right: 50px;
  border: #ccc 1px solid;
  margin: 0.2rem 0;
}
.activity-card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.2rem 0;
}

/* List */
.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

/* Grid */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}
.small-btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  /* padding: 0.1rem 0.3rem; */
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.1rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}
.searchBtn {
  display: inline-block;
  /* background: var(--light-color); */
  /* color: #333; */
  color: rgb(46, 106, 255);
  padding: 0.3rem 0.5rem;
  font-size: 0.1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 0.25rem;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.badge {
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.bgg-primary {
  /* background: var(--primary-color); */
  background: hsl(240, 0%, 42%);
  color: #fff;
}
.bg3-primary {
  /* background: var(--primary-color); */
  background: hsl(240, 0%, 42%);
  color: #fff;
  border-radius: 0.2em;
  border-width: 1.3px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  background: white;
}
.bg2-primary {
  /* background: var(--primary-color); */
  /* background: hsl(0, 0%, 75%); */
  background: white;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  color: #fff;
  height: 160px;
  border-radius: 0.2em;
  border-width: 1.3px;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  /* background: var(--primary-color); */
  background: black;
  color: #fff;
}
.btn-cancel {
  /* background: var(--primary-color); */
  background: rgb(225, 8, 8);
  color: #595959c0;
}
.btn-save {
  /* background: var(--primary-color); */
  background: rgb(1, 126, 252);
  color: #fff;
}

.btn-primary-dark,
.bg-primary,
.badge-primary,
.alert-primary {
  background: black;
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.edit-width {
  width: '800px';
  height: '800px';
}
.workshop-times {
  background-color: #aaa;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */
input {
  margin: 1.2rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

input[type='submit'],
button {
  font: inherit;
}

table th,
table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 2rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
  /* margin-bottom: 1rem; */
  height: 75px;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--light-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Mobile Styles */
@media (max-width: 900px) {
  .hide-sm {
    display: none;
  }
  .navbar {
    /* display: block; */
    text-align: center;
    height: 75px;
  }

  /* .center-content {
    justify-content: center;
  } */
}
@media (min-width: 768px) {
  .center-content {
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    /* display: block; */
    text-align: center;
    background-color: #dc3545;
    /* color: yellow; */
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }
}

.skills,
.langs {
  /* display: inline; */
  text-transform: uppercase;
  font-family: 'Anton', sans-serif;
  font-size: 4vw;
  display: inline-block;
  white-space: nowrap;
  animation: floatText 25s infinite linear;
  padding-left: 100%; /*Initial offset*/
  color: #ada97d;
  z-index: -1;
  /* display: inline; */
}

.langs {
  animation-delay: 5s;
}

.skills:hover,
.langs:hover {
  animation-play-state: paused;
}

@keyframes floatText {
  to {
    transform: translateX(-100%);
  }
}

.smallTextBigScreen {
  font-size: 12px;
  /* width: 50ch; */
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  max-height: 62px;
}
.expandedTextBigScreen {
  font-size: 12px;
  /* width: 50ch; */
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;

  /* max-height: 62px; */
}
.grid-container {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  /* background-color: #2196f3;
  padding: 10px; */
  column-gap: 8px;
}

@media only screen and (max-width: 700px) {
  .grid-container-small-screen {
    display: inline-grid;
    grid-template-columns: auto auto;
    /* background-color: #2196f3;
    padding: 10px; */
    column-gap: 8px;
  }
  .smallScreenPayFields {
    width: 340px;
  }
  .promotionText {
    background-color: gray;
    color: white;
    border-radius: 3px;
  }
  .navbar {
    /* display: block; */
    text-align: center;
    background-color: #dc3545;
    /* color: yellow; */
    height: 75px;
  }

  .navbar .flex {
    flex-direction: column;
  }
  .navbar ul {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .blog {
    display: none;
  }
  .x {
    flex-direction: column;
  }
  body {
    /* background-color: lightblue; */
  }
  label {
    font-size: 12px;
  }
  .btn {
    font-size: 12px;
  }

  .smallText p {
    font-size: 12px;
    /* width: 50ch; */
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    max-height: 62px;
  }
  .smallTextExpanded p {
    font-size: 12px;
    /* width: 50ch; */
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;

    /* max-height: 62px; */
  }
  /* .smallScreen img {
    width: 200px;
    height: 200px;
  } */
  /* .smallScreenMenu {
    width: 200px;
    height: 200px;
  } */
  .smallScreenAddDanceCompany {
    width: 30px;
    height: 30px;
  }
  .smallScreenHost img {
    width: 30px;
    height: 30px;
  }

  .smallScreen button {
    font-size: 12px;
  }
  .smallScreenAttendee img {
    width: 30px;
    /* length: 50px; */
    height: 30px;
  }
  .smallScreenAttendee label {
    font-size: 12px;
  }
  .smallScreenRoommateRequest img {
    width: 30px;
    /* length: 50px; */
    height: 30px;
  }

  .smallScreenEvents img {
    /* width: 324px; */
    width: 414px;
    height: 414px;
    /* height: 324px; */
  }
  .smallScreenCenterAd img {
    width: 75px;
    height: 75px;
  }
  .smallScreenEventDetail img {
    width: 420px;
    height: 420px;
  }

  .smallScreenText h5 {
    font-size: 14px;
  }
  .smallScreenText span {
    font-size: 18px;
  }
  .example::-webkit-scrollbar {
    display: none;
  }
}

.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #000000;
  color: #000000;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #000000;
}

.paginationActive a {
  color: white;
  background-color: gray;
}

.paginationDisabled a {
  color: grey;
  background-color: grey;
}

.sticky {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.topright {
  position: absolute;
  bottom: 2px;
  right: 8px;
  font-size: 4px;
}
.bottomright {
  position: absolute;
  bottom: 2px;
  right: 26px;
  font-size: 4px;
}

.imageRankcontainer {
  position: relative;
}

.incidentReportABC_Seperators {
  width: 80px;
  background: white;

  border-style: solid;
  border-color: rgb(1, 126, 252);
  border-width: 1px;
  border-radius: 4px;

  font-size: 20px;
  background-color: white;
  color: rgb(1, 126, 252);
  text-align: center;
  padding: 10px;
}

hr.new2 {
  border-top: 1px dashed rgb(193, 193, 193);
}

.backgroundSafety {
  height: 100%;
  background: linear-gradient(90deg, #ffc0cb 50%, #00ffff 50%);
}

.simple-linear {
  background: linear-gradient(blue, pink);
}
.angled-gradient {
  background: linear-gradient(40deg, blue, pink);
}

.trapezoid {
  border-bottom: 130px solid transparent;
  /* border-top: 0px solid blue; */
  /* uncomment below if mess up */
  /* border-left: 440px solid #66b2ff; */
  border-left: 400px solid #66b2ff;
  /* border-left: 440px solid; */
  /* background: #00308f; */

  height: 200px;

  /* width: 300px; */
}

.overlay-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -255%);
  /* background-color: #d62d2d; */
  /* background: linear-gradient(40deg, blue, pink); */

  color: #fff;
  padding: 10px 20px;
  /* font-size: 60px; */
  text-align: left;
  width: 440px;
  color: white;
  text-align: center;

  /* font-family: po; */
}

.overlay-text2 {
}
.content-1 {
  font-family: sohne-var, 'Helvetica Neue', Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28px;
  text-transform: none;
  color: #4a4a4a;
}
.offense-level-content {
  font-family: sohne-var, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  text-transform: none;
  color: #4a4a4a;
}

ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -1px;
  /* padding-le ft: 0px; */
}
ul.dashed > li:before {
  content: '- ';
  text-indent: -1px;
  /* padding-left: 0px; */
}

/* Container holding the image and the text */
.image-container-for-safe-events-tag {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  top: 8px;
  left: 14px;
  background-color: var(--light-color);
  color: #000000;
  border-radius: 16px;
  padding: 8px;
  font-size: 14px;
}
.top-right {
  position: absolute;
  top: 8px;
  right: 14px;
  /* left: 14px; */
  background-color: var(--light-color);
  color: #000000;
  border-radius: 16px;
  padding: 8px;
  font-size: 14px;
}
